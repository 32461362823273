import Vue from "vue";
import App from "./App.vue";

Vue.config.productionTip = false;
Vue.config.errorHandler = function(err, vm, info) {
  console.error(err);
  vm.$root.$emit("error", err, info);
};

new Vue({
  el: "#app",
  render: (h) => h(App),
});
