<template>
  <div id="app">
    <h1>BQ get version printer</h1>
    <small>version: 1.0.6</small>
    <h3>Status</h3>
    <ul>
      <li v-for="(row, index) in log" :key="index">
        <span :class="`text-${row.variant}`">{{ row.text }}</span>
      </li>
    </ul>
    <h3 v-if="printerName">Printer name: {{ printerName }}</h3>
    <button v-if="isShowReconnect" @click="connect">Reconnect</button>
  </div>
</template>

<script>
  /** @typedef {Object} PrintInterface
   * @property {function} connectPrinter
   * @property {function} getVersionApp
   * @property {function} getVersionAppSubversion
   * */

  const LOG_VARIANTS = {
    danger: "danger",
    primary: "primary",
    warning: "warning",
  };

  export default {
    name: "App",
    data() {
      return {
        printerName: "",
        isConnecting: false,
        isStartedInApp: false,
        log: [],
      };
    },
    computed: {
      isShowReconnect() {
        return this.isStartedInApp && !this.isConnecting
      }
    },
    mounted() {
      document.querySelector('#app-loading').style.display = 'none'
      this.subscribeToErrors();
      this.connect();
    },
    methods: {
      setLog(text, variant = LOG_VARIANTS.primary) {
        this.log.push({ text, variant });
      },
      logGetVersionApp(PrintInterface, nameMethod) {
        const method = PrintInterface[nameMethod];
        if (method) {
          try {
            this.setLog(`Call: ${nameMethod}: ${PrintInterface[nameMethod]()}`);
          } catch (err) {
            this.setLog(`Error call: ${nameMethod} ${err}`, LOG_VARIANTS.warning);
          }
        } else {
          this.setLog(`No find method: ${nameMethod}`, LOG_VARIANTS.warning);
        }
      },
      connect() {
        const { PrintInterface } = window;
        this.setLog("")
        if (PrintInterface) {
          this.setLog("Connecting")
          this.isStartedInApp = true;
          this.isConnecting = true;
          this.logGetVersionApp(PrintInterface, "getVersionApp");
          this.logGetVersionApp(PrintInterface, "getVersionAppSubversion");
          try {
            const response = PrintInterface.connectPrinter();
            if(response) {
              this.setLog(`Printer name response: ${response}`)
              const {error, printer} = JSON.parse(response)
              if(error) {
                this.setLog(`Error: ${error}`, LOG_VARIANTS.danger)
              } else {
                this.printerName = printer
              }
              this.isConnecting = false;
            }
            this.setLog("Got printer name");
          } catch (err) {
            this.setLog(
              `Get Printer Name Error ${err}`,
              LOG_VARIANTS.danger
            );
            this.isConnecting = false;
          }
        } else {
          this.setLog("Started outside the android app", LOG_VARIANTS.danger);
        }
      },
      subscribeToErrors() {
        this.$root.$on("error", (error) => {
          this.setLog(`Unregistered error: ${error}`, LOG_VARIANTS.danger);
        });
      },
    },
  };
</script>

<style>
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    margin-top: 60px;
  }

  .text-danger {
    color: #dc3545;
  }

  .text-primary {
    color: #007bff;
  }

  .text-warning {
    color: #ffc107;
  }
</style>
